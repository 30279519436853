import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/spell.css';
import '../styles/font_family.css';
import withAuth from '../users/withAuth';


function Spell_Checker() {

  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const accessToken = localStorage.getItem('accesstoken');
  const navigate = useNavigate();

  const [outputText, setOutputText] = useState(''); 
  const [selectedWord, setSelectedWord] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false); 
  const [wordIndex, setWordIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  
  const handleClick = (e) => {
    const span = e.target;
  
    if (span.tagName === 'SPAN') {
      const word = span.innerText;
      const value = span.getAttribute('value'); 

      if (span.style.color === 'red') {
        setWordIndex(value);
        setSelectedWord(word);
        sendWordToBackend(word);
        setShowSuggestions(true);
      }
    }
  };
  


  const replaceWordsByValueAttribute = (text, wordIndex, newWord) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = text;
    const spans = tempElement.getElementsByTagName('span');

    for (const span of spans) {
      const value = span.getAttribute('value');
      if (value === wordIndex) 
      {
        span.innerText = newWord;
        span.style.color = 'black';
      }
    }
    const updatedText = tempElement.innerHTML;
    return updatedText;
  };
  

  const handleWordClick = (word) => {
      const updatedText = replaceWordsByValueAttribute(outputText, wordIndex, word);
      var output = document.getElementById('translation'); 
      output.innerHTML = updatedText;
      setOutputText(updatedText);
  };

  const sendWordToBackend = async (word) => {
    try {
      // URL encode the word to handle special characters (e.g., Urdu text)
      const encodedWord = encodeURIComponent(word);
  
      // Use the encoded word in the URL
      const response = await fetch(`${process.env.REACT_APP_TECH_SERVICE}/spell_suggestions/${encodedWord}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}), // Add this if the backend expects a request body
      });
  
      if (response.ok) {
        const suggestions = await response.json();
        setSuggestions(suggestions); // Assuming setSuggestions is defined
      } else {
        console.error('Failed to get suggestions from the backend. Status:', response.status);
      }
    } catch (error) {
      console.error('Error sending word to the backend:', error);
    }
  };
  
  

  // const sendWordToBackend = async (word) => {
  //   try {
  //       const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/spell_suggestions/${word}', {
  //       // const response = await fetch(`http://10.0.0.50:8085/spell_suggestions/${word}`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //     });

  //     if (response.ok) {
  //       const suggestions = await response.json();
  //       setSuggestions(suggestions);
  //     } else {
  //       console.error('Failed to get suggestions from the backend.');
  //     }
  //   } catch (error) {
  //     console.error('Error sending word to the backend:', error);
  //   }
  // };

  


  const handleSubmit = async (event) => {
    event.preventDefault();
    const jobType = "Urdu Spell Checker";
    const body = { jobType, accessToken, text };
  
    if (text.trim() === '') {
      window.alert('Failed! Field cannot be empty.');
      return;
    }
  
    if (text.length > 3000) {
      window.alert('Length of text in the request is greater than the limit of 3000 characters');
      return;
    }
  
    try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/spell_checker', {
        // const response = await fetch('http://localhost:8085/spell_checker', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
  
      if (response.ok) {
        setErrorMessage('');
        const outputText1 = await response.text();
  
        const parser = new DOMParser();
        const doc = parser.parseFromString(outputText1, 'text/html');
        let valueCounter = 0;
  
        const spans = doc.querySelectorAll('span');
        spans.forEach((span) => {
          span.setAttribute('value', valueCounter);
          valueCounter++; 
        });
  
        var output = document.getElementById('translation');
        output.innerHTML = doc.body.innerHTML;
        setOutputText(output.innerHTML);

      } else {
        setErrorMessage('Sorry! Output is not generated. Check Spell Checker Service.');
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
    finally {
      setLoading(false);
    }
  };
  
  

  function handleOptionChange(event) {
    const selectedOption = event.target.value;

    if (selectedOption === '/text/textSum') {
      navigate('/Text_Summerization');
    }

    if (selectedOption === "/text/pos") {
      navigate('/pos_tagger');
    }

    if (selectedOption === "/text/keywordextraction") {
      navigate('/Keyword_Extraction');
    }

    if (selectedOption === "/text/udi") {
      navigate('/Domain_Identification');
    }

    if (selectedOption === "/text/usc") {
        navigate('/Spell_Checker');
    }

    if (selectedOption === "/text/stemmer") {
      navigate('/Urdu_stemmer');
  }

  // if (selectedOption === "/text/roman") {
  //   navigate('/Urdu_romanization');
  //   }

    if (selectedOption === "/text/langid") {
      navigate('/Language_identification');
    }

    if (selectedOption === "/text/contentfilter") {
      navigate('/Profanity_estimation');
    }

  }

  return (

  <div className="first-box-spell p-2" >
    {loading && (
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    )}
    
    <Helmet>
      <title>CLE Urdu Spell Checking</title>
    </Helmet>

    <div className="m-4 spell-container-well" align='center'>

        <h3 className="text-center tts-heading">CLE Urdu Spell Checking</h3>
        { errorMessage && <p className='stemmer-error-color '>{errorMessage}</p>}

        <div className="col-md-3 spell-fields-format">
          <label className='left-label-spell'>URDU Text Processing </label>
          <select id='spell-voice' onChange={handleOptionChange}>
              {/* <option value="/text/roman">Roman to Urdu Script</option> */}
              <option value="/text/pos">Urdu POS Tagging</option>
              <option value="/text/contentfilter">Urdu Profanity Estimation</option>
              <option value="/text/langid">Urdu Language Identification</option>
              <option value="/text/textSum">Urdu Text Summarization</option>
              <option value="/text/udi">Urdu Domain Identification</option>
              <option value="/text/usc" selected="selected">Urdu Spell Checking</option>
              <option value="/text/keywordextraction">Urdu Keyword Extraction</option>
              <option value="/text/stemmer">Urdu Stemmer</option>
              {/* <option value="/text/segmentation">Urdu Segmentation</option> */}
          </select> 
        </div>


        <div className="col-md-9 flex-container-button-spell">
          <form onSubmit={handleSubmit}>

              <label className="form-label spell-label"> Input </label>
              <textarea
                type="text"
                className="spell-textarea urduFont"
                value={text}
                onChange={(event) => setText(event.target.value)}
              />

              <div style={{padding:'1%'}}></div>

              <label className="form-label spell-label"> Output </label>
              <div className="form-control spell-textarea urduFont" id="translation" style={{ maxHeight: '120px', overflow: 'auto' }} onClick={handleClick} readOnly ></div>

              <div style={{paddingTop:'3%'}}> </div>
              <div className='middle-btn-spell'>
                <button type="submit" className="btn btn spell-button">Submit</button>
                <Link className="btn btn spell-button" to="/">Cancel</Link>
              </div>

          </form>
        </div>

        {showSuggestions && (
          <div id="sgstDiv" className='spell-container-well-suggestions'>
            <label className='sugguest-word-label'>Suggestions for: </label>
            <span id="selectedWord" className='urduFont'>{selectedWord}</span>
            <ul className="list-group urduFont" style={{ overflow: 'auto', maxHeight: '150px' }}>
              {suggestions.map((suggestion, index) => (
                <li className="list-group-item" key={index} onClick={() => handleWordClick(suggestion)}> {suggestion} </li>
              ))}
            </ul>
          </div>
        )}

    </div>
    <div className="spell-bottom-padding" > </div>
  </div>


  );
}

// export default Spell_Checker;

const Spell_CheckerWithAuth = withAuth(Spell_Checker);
export default Spell_CheckerWithAuth;