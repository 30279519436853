import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/langID.css';
import '../styles/font_family.css';
import withAuth from '../users/withAuth';


function Domain_Identification() {

  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const accessToken = localStorage.getItem('accesstoken');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const jobType = "Urdu Domain Identification";
    const body = { jobType, accessToken, text };
  
    // Check if the text is empty
    if (text.trim() === '') {
      window.alert('Failed! Field cannot be empty.');
      return;
    }

    // Check character limit
    if (text.length > 3000) {
      window.alert('Length of text in request is greater than the limit of 3000 characters');
      return;
    }

    try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/domain', {
        // const response = await fetch('http://localhost:8085/language_identification', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
  
      if (response.ok) {
        setErrorMessage('');
        const outputText = await response.text();
        var output = document.getElementById('translation');
        output.value = outputText;

      } else {
        setErrorMessage('Sorry! Output is not generated. Check Domain Identification Service.');
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
    finally {
      setLoading(false);
    }
  };
  

  function handleOptionChange(event) {
    const selectedOption = event.target.value;

    if (selectedOption === '/text/textSum') {
      navigate('/Text_Summerization');
    }

    if (selectedOption === "/text/pos") {
      navigate('/pos_tagger');
    }

    if (selectedOption === "/text/keywordextraction") {
      navigate('/Keyword_Extraction');
    }

    if (selectedOption === "/text/udi") {
      navigate('/Domain_Identification');
    }

    if (selectedOption === "/text/usc") {
        navigate('/Spell_Checker');
      }

      if (selectedOption === "/text/stemmer") {
        navigate('/Urdu_stemmer');
    }

    // if (selectedOption === "/text/roman") {
    //   navigate('/Urdu_romanization');
    //   }

    if (selectedOption === "/text/langid") {
      navigate('/Language_identification');
      }

      if (selectedOption === "/text/contentfilter") {
        navigate('/Profanity_estimation');
      }

  }

  return (

  <div className="first-box-langid p-2" >
    {loading && (
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    )}

    <Helmet>
    <title> CLE Urdu Domain Identification</title>
    </Helmet>

    <div className="m-4 langid-container-well" align='center'>

        <h3 className="text-center langid-heading">CLE Urdu Domain Identification</h3>
        { errorMessage && <p className='stemmer-error-color '>{errorMessage}</p>}

        <div class="col-md-3 langid-fields-format">
          <label className='left-label-langid'>URDU Text Processing </label>
          <select id='langid-voice' onChange={handleOptionChange}>
              {/* <option value="/text/roman">Roman to Urdu Script</option> */}
              <option value="/text/pos">Urdu POS Tagging</option>
              <option value="/text/contentfilter">Urdu Profanity Estimation</option>
              <option value="/text/langid" >Urdu Language Identification</option>
              <option value="/text/textSum">Urdu Text Summarization</option>
              <option value="/text/udi" selected="selected" >Urdu Domain Identification</option>
              <option value="/text/usc">Urdu Spell Checking</option>
              <option value="/text/keywordextraction">Urdu Keyword Extraction</option>
              <option value="/text/stemmer">Urdu Stemmer</option>
              {/* <option value="/text/segmentation">Urdu Segmentation</option> */}
          </select> 
        </div>

        <div class="col-md-9 flex-container-button-langid">
          <form onSubmit={handleSubmit}>

                  <label className="form-label langid-label"> Input </label>
                  <textarea
                    type="text"
                    className="form-control langid-textarea urduFont"
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                  />

                <div style={{padding:'1%'}}></div>

                  <label className="form-label langid-label"> Output </label>
                  <textarea
                    type="text"
                    className="form-control langid-textarea urduFont"
                    id = 'translation'
                    readOnly
                  />

            <div style={{paddingTop:'3%'}}> </div>
            <div className='middle-btn-langid'>
            <button type="submit" className="btn btn langid-button">Submit</button>
            <Link className="btn btn langid-button" to="/">Cancel</Link>
            </div>
          </form>
        </div>
    </div>

    <div style={{paddingBottom:'5%'}}> </div>

  </div>


  );
}

// export default Domain_Identification;

const Domain_IdentificationWithAuth = withAuth(Domain_Identification);
export default Domain_IdentificationWithAuth;
